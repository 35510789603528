import profile1 from '../assests/profile1.jpg'

export const TestimonialsData = [
    {
        image : profile1,
        Comment : "Conscious Skincare packages all their products in recyclable materials and offer a unique, high quality brand that we feel a perfect match for the excellence of The Royal Ballet.",
        name : "John Alendon " 
    },

    {
        image : profile1,
        Comment : "Conscious Skincare packages all their products in recyclable materials and offer a unique, high quality brand that we feel a perfect match for the excellence of The Royal Ballet.",
        name : "John Alendon " 
    },

    {
        image : profile1,
        Comment : "Conscious Skincare packages all their products in recyclable materials and offer a unique, high quality brand that we feel a perfect match for the excellence of The Royal Ballet.",
        name : "John Alendon " 
    },

    {
        image : profile1,
        Comment : "Conscious Skincare packages all their products in recyclable materials and offer a unique, high quality brand that we feel a perfect match for the excellence of The Royal Ballet.",
        name : "John Alendon " 
    },

    {
        image : profile1,
        Comment : "Conscious Skincare packages all their products in recyclable materials and offer a unique, high quality brand that we feel a perfect match for the excellence of The Royal Ballet.",
        name : "John Alendon " 
    },
    {
        image : profile1,
        Comment : "Conscious Skincare packages all their products in recyclable materials and offer a unique, high quality brand that we feel a perfect match for the excellence of The Royal Ballet.",
        name : "John Alendon " 
    },

]